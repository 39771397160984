import React from 'react';

import Icon from '../../styles/atoms/icons';

export const NextArrow = props => {
  const { isTestimonial, className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      role="button"
      aria-label="Previous"
      tabIndex={0}
      onClick={onClick}
    >
      <Icon
        id={isTestimonial ? 'arrow_blue' : 'carousel-right-arrow'}
        style={{ width: '32px', height: '32px' }}
        isImage
      />
    </div>
  );
};

export const PrevArrow = props => {
  const { isTestimonial, className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      role="button"
      aria-label="Next"
      tabIndex={0}
      onClick={onClick}
    >
      <Icon
        id={isTestimonial ? 'arrow_blue' : 'carousel-left-arrow'}
        style={{ width: '32px', height: '32px' }}
        isImage
      />
    </div>
  );
};
