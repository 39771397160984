import styled from 'styled-components';

import { font } from '../../atoms/typography';
import { colors } from '../../atoms/colors';
import { atMinWidth } from '../../atoms/breakpoints';

import arrowStandard from '../../../images/arrowStandard.svg';
import shadow from '../../atoms/shadows';

export const Wrapper = styled.div`
  position: relative;
  display: ${props => (props.variant === 'Split' ? 'flex' : 'contents')};
  flex-flow: column nowrap;
  align-items: center;
  overflow: hidden;

  ${atMinWidth.md`
    flex-direction: row;
  `}
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  align-items: ${props =>
    props.variant === 'Split' ? 'flex-start' : 'center'};
  text-align: ${props => (props.variant === 'Split' ? 'left' : 'center')};
  max-width: 770px;
  margin: 0 auto;

  .link {
    width: fit-content;
  }
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${colors.primary[500]};
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')};
  color: ${colors.gray[900]};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')};
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')};
  `}
`;

export const Subhead = styled.div`
  ${font('text', 'md', '400')};
  color: ${colors.gray[600]};

  ${atMinWidth.sm`
    ${font('text', 'lg', '400')};
  `}

  ${atMinWidth.md`
    ${font('text', 'xl', '400')}
  `}
`;

export default styled.section``;

export const CareersCarouselContainer = styled.div`
  .justify-content-initial {
    justify-content: initial !important;
  }
  .card-testimonial {
    display: block !important;
    padding: 24px;
    max-width: 980px;
    min-height: 332px;
    background: #ffffff;
    box-shadow: 0px 24px 48px -12px rgba(19, 48, 205, 0.15);
    border-radius: 20px;
    margin: 0 auto;
    @media (max-width: 1200px) {
      max-width: 770px;
    }
    @media (max-width: 991px) {
      max-width: 560px;
    }
    @media (max-width: 768px) {
      padding: 12px;
      max-width: 306px;
      box-shadow: 0px 10px 15px -12px rgba(19, 48, 205, 0.15);
    }
    .profile-image {
      width: 100%;
      min-width: 249px;
      max-width: 249px;
      @media (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 20px;
      }
      @media (max-width: 768px) {
        max-height: 284px;
      }
      .gatsby-image-wrapper {
        border-radius: 20px;
        @media (max-width: 768px) {
          max-height: 284px;
        }
      }
    }
    .details {
      max-width: 595px;
      padding-left: 42px;
      @media (max-width: 1200px) {
        padding-left: 0;
        max-width: 430px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
      }
      .quotes,
      .quotes p {
        font-size: 22.5px;
        line-height: 150%;
        letter-spacing: -0.27px;
        color: #4d5061;
        @media (max-width: 768px) {
          text-align: left;
          font-size: 18px;
        }
      }
      .emp-info {
        .work {
          display: flex;
          margin-bottom: 12px;
          @media (max-width: 500px) {
            flex-wrap: wrap;
          }
          .emp-name {
            ${font('display', 'sm', '700')}
            color: #4d5061;
            margin-right: 12px;
            @media (max-width: 767px) {
              text-align: left;
              font-size: 18px;
            }
            @media (max-width: 414px) {
              text-align: left;
              font-size: 16px;
            }
          }
          .role {
            font-size: 22.5px;
            line-height: 110%;
            letter-spacing: -0.27px;
            color: #4d5061;
            @media (max-width: 767px) {
              text-align: left;
              font-size: 18px;
            }
            @media (max-width: 414px) {
              text-align: left;
              font-size: 16px;
            }
          }
        }
        p {
          margin-bottom: 0;
        }
        p.location {
          font-size: 22.5px;
          line-height: 110%;
          letter-spacing: -0.27px;
          color: #86899a;
          @media (max-width: 767px) {
            font-size: 18px;
          }
          @media (max-width: 414px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .arrows-wrapper {
    position: absolute;
    top: 40%;
    width: 100%;
    @media (max-width: 767px) {
      display: none;
      top: auto;
      bottom: -8%;
    }
    @media (max-width: 500px) {
      bottom: 72px;
    }
    .slider-arrows {
      display: flex;
      justify-content: space-between;
      z-index: 1000;
      @media (max-width: 767px) {
        justify-content: space-between;
        width: 105px;
        margin: 0 auto;
      }
      .prev-arrow {
        transform: scaleX(-1);
      }
      .next-arrow {
        margin-right: 30px;
      }
      .prev-arrow,
      .next-arrow {
        cursor: pointer;
      }
    }
  }
  .slick-slide {
    min-height: 440px;
    @media (max-width: 991px) {
      min-height: 700px;
    }
  }
  ul.slick-dots {
    text-align: center;
    @media (max-width: 767px) {
      display: none !important;
      bottom: -102px;
    }
    @media (max-width: 500px) {
      bottom: 32px;
    }
    li {
      margin: 0;
      button:before {
        font-size: 10px;
        color: #d8dae6;
        opacity: 1;
      }
      &.slick-active {
        button:before {
          font-size: 10px;
          color: var(--primary-color);
          opacity: 1;
        }
      }
    }
  }
`;

export const StandardStyles = styled.div`
  .testimonials {
    overflow: hidden;
    margin-top: 64px;
    position: relative;
    .swiper {
      position: relative;
      padding: 30px 50px 64px 50px;
      @media (max-width: 991px) {
        padding: 30px 30px 64px 30px;
      }
      &:before {
        content: '';
        background: linear-gradient(
          to right,
          ${props => props.theme},
          rgba(255, 255, 255, 0)
        );
        width: 30px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        @media (max-width: 991px) {
          width: 30px;
        }
      }
      &:after {
        content: '';
        background: linear-gradient(
          to left,
          ${props => props.theme},
          rgba(255, 255, 255, 0)
        );
        width: 30px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        @media (max-width: 991px) {
          width: 30px;
        }
      }
      .swiper-button-next {
        position: absolute;
        right: 0;
        top: 50%;
        width: 32px;
        @media (max-width: 991px) {
          display: none;
        }
        &:after {
          content: '';
          background: url(${arrowStandard});
          background-repeat: no-repeat;
          width: 32px;
          height: 32px;
        }
      }
      .swiper-button-prev {
        position: absolute;
        left: 0;
        top: 50%;
        width: 32px;
        transform: rotate(180deg);
        @media (max-width: 991px) {
          display: none;
        }
        &:after {
          content: '';
          background: url(${arrowStandard});
          background-repeat: no-repeat;
          width: 32px;
          height: 32px;
        }
      }
      .swiper-slide {
        width: 100%;
        max-width: 370px;
        @media (max-width: 768px) {
          max-width: 390px;
        }
      }
    }
  }
  .standard-card {
    ${shadow('lg')}
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    height: 252px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .quote {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: -0.16px;
      color: #6f7283;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .customer {
      display: flex;
      align-items: center;
      font-size: 14px;
      .icon {
        margin-bottom: 0;
        margin-right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        img {
          width: 64px;
          height: 64px;
        }
        .letter-avatar {
          width: 100%;
          height: 100%;
          line-height: 64px;
          display: inline-block;
          color: #fff;
          background: linear-gradient(107.79deg, #c0c3d1 5.1%, #d8dae6 89.41%);
          font-weight: 700;
          font-size: 22.5px;
          text-align: center;
        }
      }
      &-info {
        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14.4px;
          line-height: 150%;
          color: #1d2030;
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
          color: var(--subtle-text-color);
        }
      }
    }
  }
`;

export const SplitStyles = styled.div`
  .testimonials {
    position: relative;
    .swiper {
      position: relative;
      padding: 50px 50px 80px 50px;
      min-width: 1000px;
      @media (max-width: 991px) {
        padding: 50px 30px 80px 30px;
      }
      @media (max-width: 768px) {
        padding: 50px 30px 80px 0px;
      }
      &:before {
        content: '';
        background: linear-gradient(
          to right,
          ${props => props.theme},
          rgba(255, 255, 255, 0)
        );
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        @media (max-width: 991px) {
          width: 30px;
        }
      }
      &:after {
        content: '';
        background: linear-gradient(
          to left,
          ${props => props.theme},
          rgba(255, 255, 255, 0)
        );
        width: 50px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        @media (max-width: 991px) {
          width: 30px;
        }
      }
      .swiper-button-next::after,
      .swiper-button-prev::after {
        content: '';
        background-image: none;
      }
      .swiper-slide {
        width: 100%;
        max-width: 570px;
        @media (max-width: 1200px) {
          max-width: 370px;
        }
        @media (max-width: 768px) {
          max-width: 300px;
        }
      }
    }
  }
  .split-card {
    box-shadow: 0px 8px 60px rgba(23, 30, 42, 0.06);
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    height: 252px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .quote {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: -0.16px;
      color: #6f7283;
      max-width: 86%;
      @media (max-width: 1200px) {
        max-width: 100%;
      }
    }
    .customer {
      display: flex;
      align-items: center;
      font-size: 14px;
      .icon {
        margin-bottom: 0;
        margin-right: 16px;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        img {
          width: 64px;
          height: 64px;
        }
        .letter-avatar {
          width: 100%;
          height: 100%;
          line-height: 64px;
          display: inline-block;
          color: #fff;
          background: linear-gradient(107.79deg, #c0c3d1 5.1%, #d8dae6 89.41%);
          font-weight: 700;
          font-size: 22.5px;
          text-align: center;
        }
      }
      &-info {
        p {
          font-style: normal;
          font-weight: 700;
          font-size: 14.4px;
          line-height: 150%;
          color: #1d2030;
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
          color: var(--subtle-text-color);
        }
      }
    }
  }
`;
