import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { CareersCarouselContainer } from '../../styles/Components/TestimonialCarousel/TestimonialStyles';
import Image from '../../utils/OptimizedImage';
import { NextArrow, PrevArrow } from '../base/Arrows';

const CareersCarousel = ({ testimonial }) => {
  const sliderRef = useRef();
  const isMobile = useMediaQuery('(max-width: 991px)');
  const settingsCareerTestimonials = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <CareersCarouselContainer>
      <Container>
        <Row>
          <Col lg={12}>
            <Slider {...settingsCareerTestimonials} ref={sliderRef}>
              {testimonial.map(item => (
                <div className="card-testimonial">
                  <Row className="justify-content-initial align-items-center">
                    <Col lg="auto" md={12} sm={12} xs={12}>
                      <div className="profile-image">
                        {
                          item?.person?.headshot.image.gatsbyImageData ?
                            (
                              <Image
                                image={
                                  isMobile && item?.person?.headshot?.imageMobile?.gatsbyImageData
                                    ? item?.person.headshot.imageMobile.gatsbyImageData
                                    : item?.person.headshot.image.gatsbyImageData
                                }
                                alt={`Artistic Interpretation (Avatar) of ${item?.person?.firstName} ${item?.person?.lastName}`}
                              />
                            )
                            :
                            (
                              <Image
                                src={
                                  isMobile && item?.person?.headshot?.imageMobile?.url
                                    ? item?.person.headshot.imageMobile.url
                                    : item?.person.headshot.image.url
                                }
                                alt={`Artistic Interpretation (Avatar) of ${item?.person?.firstName} ${item?.person?.lastName}`}
                              />
                            )
                        }
                      </div>
                    </Col>
                    <Col lg="auto" md={12} sm={12} xs={12}>
                      <div className="details">
                        <div className="quotes" dangerouslySetInnerHTML={{ __html: item?.quote }} />
                        <div className="emp-info">
                          <div className="work">
                            {(item?.person?.firstName || item?.person?.lastName) && (
                              <p className="emp-name">{item?.person.firstName} {item?.person.lastName}</p>
                            )}
                            {item?.person?.role && <p className="role">{item?.person.role}</p>}
                          </div>
                          {item?.person?.company && (
                            <p className="location">{item?.person.company?.location}</p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Slider>
            <div className="arrows-wrapper">
              <div className="slider-arrows">
                <PrevArrow
                  isTestimonial
                  className="prev-arrow"
                  onClick={() => sliderRef.current.slickPrev()}
                />
                <NextArrow
                  isTestimonial
                  className="next-arrow"
                  onClick={() => sliderRef.current.slickNext()}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </CareersCarouselContainer>
  );
};

export default CareersCarousel;
